/* ---------- HEADER ------------- */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Figtree";
}

ul {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  align-items: center;
  margin: 28px 0px 16px 0px;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  letter-spacing: -0.03em;
  width: 118px;
  height: 100px;
}

.favoriteButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: linear-gradient(228.72deg, #8f14ff 6.71%, #ab50fe 96.21%);
  background-size: 200% auto;
  border: 0;
  width: 185px;
  height: 38px;
  border-radius: 7px;
  color: aliceblue;
  transition: all 0.9s ease-in-out;
}

.favoriteButton > a,
.favoriteButton > span {
  color: aliceblue;
  font-weight: 500;
}
.headerMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 50px !important;
  width: 650px;
  font-weight: 700;
  font-family: "Manrope";
  text-align: center;
  line-height: 46px;
  transition: all ease-in-out 0.3s;
  transform: translateY(50px);
  opacity: 0;
  animation: fadeSlide 0.3s forwards;
  position: relative;
}
.underlineTitle {
  position: absolute;
  top: 102px;
  right: 141px;
  animation: fadeIn 0.9s forwards;
  opacity: 0;
  animation-delay: 0.6s;
}

.arrowTitle {
  position: absolute;
  top: 62px;
  left: -25px;
  animation: fadeIn 0.9s forwards;
  opacity: 1;
  animation-delay: 1s;
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

h2 {
  font-size: 20px;
  font-family: "Figtree";
  font-weight: 300;
  width: 670px;
  text-align: center;
  margin: 0px 0px 40px 0px;
  animation: fadeSlide 0.3s forwards;
  animation-delay: 0.1s;
  opacity: 0;
  position: relative;
}

/* ------ FORM ---------- */

.searchForm {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 0px 42px 18px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin-top: 52px;
  opacity: 0;
  animation: fadeSlide 0.3s forwards;
  animation-delay: 0.3s;
  z-index: 3;
}

.searchForm > input {
  outline: none;
  border-radius: 10px;
  border: 0;
  height: 50px;
  width: 666px;
  padding-left: 30px;
  font-family: "Figtree";
  font-weight: 400;
}

.submitButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: linear-gradient(228.72deg, #8f14ff 6.71%, #ab50fe 96.21%);
  color: aliceblue;
  font-family: "Figtree";
  font-weight: 500;
  height: 100%;
  right: 0;
  width: 135px;
  border-radius: 0px 10px 10px 0px !important;
  box-shadow: 0px 0px 42px 18px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

/* --------- BRANDSLOGOS ------------ */

.dysonLogo {
  width: 200px;
  height: 50px;
  background: url(/src/assets/dyson.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.siemensLogo {
  width: 200px;
  height: 50px;
  background: url(/src/assets/siemens.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.phillipsLogo {
  width: 200px;
  height: 50px;
  background: url(/src/assets/karcher.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.rowentaLogo {
  width: 200px;
  height: 50px;
  background: url(/src/assets/bosch.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.logitechLogo {
  width: 200px;
  height: 50px;
  background: url(/src/assets/logitech.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.brands {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandCarousel > p {
  margin-top: 115px;
  margin-bottom: 45px;
  text-align: center;
  font-family: "Figtree";
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(228.72deg, #ffffff 6.71%, #ffffff 96.21%);
  opacity: 0;
  color: aliceblue;
  transition: opacity 0.2s ease-in-out;
}

.button:hover::before {
  opacity: 0.1;
}

.favoriteButton,
.submitButton {
  border-radius: 7px;
}

.arrowRedirect {
  transition: opacity 0.1s ease-in-out;
}

.arrowRedirect:hover::before {
  opacity: 0.2;
}

.brands {
  width: 1300px;
  overflow: hidden;
  display: flex;
  position: relative;
  margin: 100px 0px 100px 0px;
}
.brands:before,
.brands:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
.brands:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.brands:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.brands:hover .brand-slide {
  animation-play-state: paused;
}
.brand-slide {
  animation: slide 35s linear infinite;
  display: flex;
}

.brand-slide > img {
  width: 200px;
  object-fit: contain;
  height: 51px;
  margin-right: 100px;
  cursor: pointer;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -100%
    ); /* Défilement jusqu'à la moitié de la largeur pour une transition en douceur */
  }
}
