h3 {
  font-size: 35px;
  text-align: center;
  font-family: "Manrope";
  margin: 135px 0px 80px 0px;
}
#root {
  position: relative;
  overflow: hidden;
  padding: 30px 100px;
}
.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.card {
  width: 330px;
  height: 680px;
  box-shadow: 0px 0px 48px 2px rgba(0, 0, 0, 0.11);
  border-radius: 41px;
  position: relative;
}

.error404 {
  font-family: "Figtree";
  font-size: 23px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin: 200px 0px 200px 0px;
}

.cardImg {
  width: 330px;
  height: 180px;
  object-fit: contain;
  margin: 20px 0px 20px 0px;
}

.icons {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 1px;
}

h4 {
  font-family: "Figtree";
  font-weight: 600;
  text-align: center;
  font-size: 17px;
  margin: 30px 35px 30px 35px;
  position: relative;
}
.hamburger-react {
  display: none;
}

.modal-legal {
  display: none;
}

.modal-hide > ul {
  position: absolute;
  right: 329px;
  top: 72px;
  font-family: "Figtree";
}

.modal-link {
  position: relative;
}

.modal-link::after {
  content: "";
  position: absolute;
  background: linear-gradient(228.72deg, #8f14ff 6.71%, #ab50fe 96.21%);
  width: 0%;
  bottom: -5px;
  left: 0;
  height: 2px;
  transition: all 0.2s ease-in;
}

.modal-link:hover::after {
  width: 100%;
}
.raw {
  display: flex;
  align-items: center;
  gap: 25px;
  position: relative;
  margin-bottom: 20px;
}

.raw::after {
  content: "";
  position: absolute;
  margin-bottom: -40px;
  width: 80%;
  height: 1px;
  background: rgba(93, 93, 93, 0.142);
  padding: 0px 10px 0px 10px;
  left: 50%;
  transform: translate(-50%);
}

header {
  margin-top: 25px;
}

.countryFlag {
  width: 28px;
  height: 23px;
  margin-left: 25px;
  object-fit: contain;
  border-radius: 3px;
  box-shadow: #0000;
}

.priceItem {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
}

.arrowRedirect {
  width: 26px;
  height: 22px;
  background: url(/src/assets/arrow-right.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 20px;
  background-color: #8f00ff;
  border-radius: 3px;
}

.arrowRedirect > a {
  position: absolute;
  width: 26px;
  height: 22px;
  right: 0px;
  color: transparent;
}

.card > h4 {
  display: -webkit-box; /* Affiche le texte comme une boîte avec un nombre limité de lignes */
  -webkit-line-clamp: 2; /* Limite le texte à 2 lignes */
  -webkit-box-orient: vertical; /* Oriente la boîte verticalement */
  overflow: hidden; /* Masque le texte débordant */
  text-overflow: ellipsis; /* Ajoute les trois petits points */
  height: 37px;
}
.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
  animation: s4 4s infinite;
  position: absolute;
  left: 48%;
  bottom: 440px;
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color: #766df4 #766df4 #0000 #0000;
  mix-blend-mode: darken;
  animation: s4 1s infinite linear;
}
.custom-loader::after {
  border-color: #0000 #0000 #e4e4ed #e4e4ed;
  animation-direction: reverse;
}

@keyframes s4 {
  100% {
    transform: rotate(1turn);
  }
}

.hideCarousel {
  display: none;
}

.products {
  display: none;
}
.searchResult {
  margin: 85px 0px 75px 0px;
}

.heart {
  position: relative;
  cursor: pointer;
}

.heart-start-animation {
  clip-path: circle(111.2% at 51% 100%) !important;
}

.heart-animation {
  position: absolute;
  width: 24px;
  left: 0;
  transition: all ease-in-out 0.1s;
  clip-path: circle(0% at 51% 100%);
}

.favorite {
  margin-top: 60px;
  margin-bottom: 80px;
}

.main-menu {
  position: relative;
}

p {
  font-family: "Figtree";
}

.questionElement {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  overflow: hidden;
  height: 96px;
  background: rgb(255, 255, 255);
  padding: 40px 40px 40px 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Figtree";
  position: relative;
  cursor: pointer;
}
.pageTitle {
  font-size: 30px;
  width: 100%;
  margin: 35px 0px 80px 0px;
}
.mentions > ul {
  font-family: "Figtree";
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-start;
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 0;
}

.pagesTitle {
  margin: 50px;
}

.noFavorite {
  margin-top: 100px;
  margin-bottom: 100px;
  font-family: "Figtree";
  font-size: 16px;
}

.delete {
  color: rgb(207, 8, 8);
  top: 197px;
  right: 21px;
  cursor: pointer;
  position: absolute;
}

.product-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.questions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.questionText {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  height: 16px;
}

.long-text {
  font-size: 15px;
  color: rgb(69, 69, 69);
  position: absolute;
  width: 92%;
  top: 88px;
}
.social-logo {
  width: 80px;
}
.social-tiktok,
.social-instagram,
.social-facebook,
.social-youtube {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
}

.social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 150px 0px 246px 0px;
  gap: 90px;
}

.social-link {
  font-family: "Figtree";
  font-weight: 600;
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.social-username {
  font-weight: 600;
  margin: 0;
}
.animation-modal-down {
  animation: slideDown 0.5s forwards;
}

.animation-modal-up {
  animation: slideUp 0.5s forwards;
}

.notification-bar {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 300px;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #8f14ff;
  color: white;
  font-family: "Figtree";
  font-weight: 300;
  position: relative;
  width: calc(100% + 200px);
  left: -100px;
  top: -155px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.button-extension {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-radius: 7px;
  border: 1px solid white;
  box-sizing: border-box;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
  background: #8f00ff;
  transition: all 0.1s ease-in;
  cursor: pointer;
  position: relative;
}

.button-extension > a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.button-extension:hover {
  background: white;
  color: #8f00ff;
}

.button-extension > img {
  width: 25px;
}

@keyframes slideDown {
  from {
    height: 96px;
  }
  to {
    height: 290px;
  }
}
@keyframes slideUp {
  from {
    height: 290px;
  }
  to {
    height: 96px;
  }
}

.short-text {
  font-size: 15px;
  color: rgb(69, 69, 69);
  position: absolute;
  width: 92%;
  top: 88px;
}
