footer {
  display: flex;
  justify-content: space-between;
  font-family: "Figtree";
  margin: 145px 0px 120px 0px;
}

.information > ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  font-size: 17px;
  padding: 0;
}

#name {
  font-weight: 700;
}

#affiliate {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 77px;
}
#dev {
  text-align: center;
  width: 500px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 60px;
}
