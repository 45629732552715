.lights {
  position: absolute;
  opacity: 0.4;
  overflow-x: hidden;
  width: 594.75px;
  height: 594.75px;
  filter: blur(100px);
  will-change: transform;
}

#light1 {
  top: 203px;
  left: -591px;
  transform: rotate(-20deg);
  background: -moz-linear-gradient(
    top,
    rgba(255, 200, 6, 0.55) 1.9%,
    rgba(255, 199, 0, 0.55) 1.91%,
    rgba(255, 185, 5, 0.55) 37.15%,
    rgba(251, 140, 9, 0.55) 100.9%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1.9%, rgba(255, 200, 6, 0.55)),
    color-stop(1.91%, rgba(255, 199, 0, 0.55)),
    color-stop(37.15%, rgba(255, 185, 5, 0.55)),
    color-stop(100.9%, rgba(251, 140, 9, 0.55))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 200, 6, 0.55) 1.9%,
    rgba(255, 199, 0, 0.55) 1.91%,
    rgba(255, 185, 5, 0.55) 37.15%,
    rgba(251, 140, 9, 0.55) 100.9%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 200, 6, 0.55) 1.9%,
    rgba(255, 199, 0, 0.55) 1.91%,
    rgba(255, 185, 5, 0.55) 37.15%,
    rgba(251, 140, 9, 0.55) 100.9%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 200, 6, 0.55) 1.9%,
    rgba(255, 199, 0, 0.55) 1.91%,
    rgba(255, 185, 5, 0.55) 37.15%,
    rgba(251, 140, 9, 0.55) 100.9%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 200, 6, 0.55) 1.9%,
    rgba(255, 199, 0, 0.55) 1.91%,
    rgba(255, 185, 5, 0.55) 37.15%,
    rgba(251, 140, 9, 0.55) 100.9%
  );
}

#light2 {
  top: 153px;
  right: -550px;
  transform: rotate(-20deg);
  background: -moz-linear-gradient(
    top,
    rgba(50, 164, 227, 0.5) 1.9%,
    rgba(41, 190, 223, 0.5) 1.91%,
    rgba(34, 173, 252, 0.5) 37.15%,
    rgba(48, 129, 204, 0.5) 100.9%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1.9%, rgba(50, 164, 227, 0.5)),
    color-stop(1.91%, rgba(41, 190, 223, 0.5)),
    color-stop(37.15%, rgba(34, 173, 252, 0.5)),
    color-stop(100.9%, rgba(48, 129, 204, 0.5))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(50, 164, 227, 0.5) 1.9%,
    rgba(41, 190, 223, 0.5) 1.91%,
    rgba(34, 173, 252, 0.5) 37.15%,
    rgba(48, 129, 204, 0.5) 100.9%
  );
  background: -o-linear-gradient(
    top,
    rgba(50, 164, 227, 0.5) 1.9%,
    rgba(41, 190, 223, 0.5) 1.91%,
    rgba(34, 173, 252, 0.5) 37.15%,
    rgba(48, 129, 204, 0.5) 100.9%
  );
  background: -ms-linear-gradient(
    top,
    rgba(50, 164, 227, 0.5) 1.9%,
    rgba(41, 190, 223, 0.5) 1.91%,
    rgba(34, 173, 252, 0.5) 37.15%,
    rgba(48, 129, 204, 0.5) 100.9%
  );
  background: linear-gradient(
    to bottom,
    rgba(50, 164, 227, 0.5) 1.9%,
    rgba(41, 190, 223, 0.5) 1.91%,
    rgba(34, 173, 252, 0.5) 37.15%,
    rgba(48, 129, 204, 0.5) 100.9%
  );
}

#light3 {
  position: absolute;
  bottom: -590px;
  left: -253px;
  transform: rotate(-20deg);
  background: -moz-linear-gradient(
    top,
    rgba(207, 27, 201, 0.5) 1.9%,
    rgba(223, 41, 123, 0.5) 1.91%,
    rgba(175, 0, 143, 0.5) 37.15%,
    rgba(235, 13, 217, 0.5) 100.9%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1.9%, rgba(50, 164, 227, 0.5)),
    color-stop(1.91%, rgba(41, 190, 223, 0.5)),
    color-stop(37.15%, rgba(34, 173, 252, 0.5)),
    color-stop(100.9%, rgba(48, 129, 204, 0.5))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(207, 27, 201, 0.5) 1.9%,
    rgba(223, 41, 123, 0.5) 1.91%,
    rgba(175, 0, 143, 0.5) 37.15%,
    rgba(235, 13, 217, 0.5) 100.9%
  );
  background: -o-linear-gradient(
    top,
    rgba(207, 27, 201, 0.5) 1.9%,
    rgba(223, 41, 123, 0.5) 1.91%,
    rgba(175, 0, 143, 0.5) 37.15%,
    rgba(235, 13, 217, 0.5) 100.9%
  );
  background: -ms-linear-gradient(
    top,
    rgba(207, 27, 201, 0.5) 1.9%,
    rgba(223, 41, 123, 0.5) 1.91%,
    rgba(175, 0, 143, 0.5) 37.15%,
    rgba(235, 13, 217, 0.5) 100.9%
  );
  background: linear-gradient(
    to bottom,
    rgba(207, 27, 201, 0.5) 1.9%,
    rgba(223, 41, 123, 0.5) 1.91%,
    rgba(175, 0, 143, 0.5) 37.15%,
    rgba(235, 13, 217, 0.5) 100.9%
  );
}
