@media only screen and (max-width: 1180px) {
  .questionElement {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 330px;
    overflow: hidden;
    height: 96px;
    background: rgb(255, 255, 255);
    padding: 40px 40px 40px 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: "Figtree";
    z-index: 1;
  }
  .questionText {
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
  }

  .long-text {
    font-size: 14px;
    color: rgb(69, 69, 69);
    position: absolute;
    width: 76%;
    top: 86px;
  }

  .animation-modal-down {
    animation: slideDown 0.5s forwards;
  }

  .animation-modal-up {
    animation: slideUp 0.5s forwards;
  }
  @keyframes slideDown {
    from {
      height: 96px;
    }
    to {
      height: 520px;
    }
  }
  @keyframes slideUp {
    from {
      height: 520px;
    }
    to {
      height: 96px;
    }
  }

  .short-text {
    font-size: 14px;
    color: rgb(69, 69, 69);
    position: absolute;
    width: 76%;
    top: 109px;
  }
  .brandCarousel {
    display: none;
  }
}

.notification-bar {
  padding: 0px 150px;
}

@media only screen and (max-width: 960px) {
  .modal-hide {
    display: none;
  }
}
@media only screen and (max-width: 750px) {
  .modal-hide {
    display: block;
  }
  .notification-bar {
    display: none;
  }

  header {
    margin: 0;
  }
  body {
    padding: 0px !important;
  }
  .modal-link::after {
    display: none;
  }
  .information {
    margin: 0px 0px 40px 0px;
  }
  #dev {
    text-align: inherit;
  }
  #root {
    position: relative;
    overflow-x: hidden;
    padding: 10px 30px;
  }

  .modal-open {
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 60%;
    z-index: 5;
    background: white;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out; /* Préfixe pour Safari */
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .modal-hide {
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 0%;
    z-index: 5;
    background: white;
    transition: all 0.3s ease-out;
  }

  .modal-hide > ul {
    visibility: hidden;
  }

  .modal-open > ul {
    position: absolute;
    top: 108px;
    left: -8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Figtree";
    font-weight: 500;
    font-size: 21px;
    animation: fadeSlide 0.5s forwards;
    animation-delay: 0.1s;
    opacity: 0;
    gap: 25px;
  }

  @keyframes fadeSlide {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .background-on-modal {
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background: #00000072;
    z-index: 4;
  }
  .modal-legal {
    font-weight: 700 !important;
    top: 640px !important;
    gap: 10px !important;
    font-size: 19px !important;
    position: absolute !important;
  }
  .modal-link-legal {
    font-family: "Figtree";
    font-weight: 200;
    font-size: 18px;
  }
  .modal-link {
    color: #8f14ff;
  }
  .hamburger-react {
    left: 24px;
    position: absolute !important;
    top: 37px;
    display: block !important;
  }
  .favoriteButton {
    color: transparent;
    width: 40px;
    right: 10;
    border-radius: 50px;
    position: absolute;
  }
  .favoriteButton > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    width: 118px;
  }
  #about {
    display: none;
  }
  nav > ul {
    position: absolute;
    right: 55px;
    top: 33px;
  }
  nav {
    justify-content: center;
  }
  .custom-loader {
    bottom: 596px;
    left: 44%;
  }
  h1 {
    font-size: 30px !important;
    width: 100%;
    font-weight: 700;
    font-family: "Manrope";
    text-align: start;
    line-height: 31px;
    margin: 24px 0px 30px !important;
  }
  .underlineTitle,
  .card,
  .searchForm {
    width: 100%;
  }
  h2 {
    font-size: 15px;
    font-family: "Figtree";
    font-weight: 300;
    width: 100%;
    text-align: start;
    margin: 5px 0px 39px 0px;
  }
  h3 {
    font-size: 27px;
    margin: 80px 0px 60px 0px;
  }
  .arrowTitle {
    position: absolute;
    width: 41px;
    top: 66px;
    right: 12px;
    image-orientation: revert;
    transform: scaleX(-1) rotate(8deg);
    left: inherit;
  }
  .searchForm > input {
    height: 42px;
    width: 100%;
    font-size: 16px;
  }
  .submitButton {
    color: transparent;
    width: 50px;
  }
  .submitButton > span {
    color: aliceblue !important;
    position: absolute;
  }
  .brandCarousel,
  .underlineTitle {
    display: none;
  }
  .pageTitle {
    font-size: 30px;
    width: 100%;
    margin: 35px 0px 70px 0px;
  }
  .favorite {
    margin-top: 37px;
    margin-bottom: 44px;
  }
  footer {
    flex-direction: column;
    margin: 75px 0px 90px 0px;
  }

  .social-logo {
    width: 80px;
  }
  .social-tiktok,
  .social-instagram,
  .social-facebook,
  .social-youtube {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin: 100px 0px 20px 0px;
  }

  .social-link {
    font-family: "Figtree";
    font-weight: 600;
  }

  .questions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  #right {
    margin: 0;
  }

  #dev {
    width: auto;
    position: inherit;
    transform: none;
  }
}

@media only screen and (max-width: 385px) {
  .underlineTitle {
    right: 207px;
  }
  .card,
  .cardImg {
    width: 300px;
  }
}

.pagesTitle {
  margin: 50px 0px 60px 0px;
  font-size: 26px;
}
